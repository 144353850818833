import { Outlet } from "react-router-dom";
import Navigation from "./Components/Navigation";

export default function App() {
    return (
        <>
            <Navigation />
            <div className="mx-auto max-w-5xl px-4 pb-6">
                <Outlet />
            </div>
        </>
    );
}
