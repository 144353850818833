import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Popover from "../Components/Popover.js";
import TagManager from "react-gtm-module";
import Spinner from "../Components/Spinner";

export default function Home() {
    const [isLoading, setIsLoading] = useState(true); // Loading state

    const bannerImage = "img/pic.jpg";

    const projects = [
        {
            link: "/gotomarket",
            image: "img/gotomarket.svg",
            alt: "Gotomarket.tools",
            title: "gotomarket.tools",
            description: "eCommerce landing page generator",
            tags: [
                "Node",
                "Express",
                "React",
                "Mongo",
                "Tailwind",
                "Heroku",
                "Sendgrid",
                "Git",
            ],
        },
        {
            link: "/secretsanta",
            image: "img/secretsanta.svg",
            alt: "Secretsanta.tv",
            title: "secretsanta.tv",
            description: "Secret Santa event manager",
            tags: [
                "Node",
                "Express",
                "React",
                "Mongo",
                "Tailwind",
                "Heroku",
                "Sendgrid",
                "Git",
            ],
        },
        {
            link: "/getreviews",
            image: "img/getreviews.svg",
            alt: "GetReviews.ai",
            title: "getreviews.ai",
            description: "Review generation app",
            tags: [
                "Frontend Development",
                "Strategy",
                "Sales",
                "Marketing",
                "Support",
            ],
            popover: {
                imgSrc: "img/dollaricon.png",
                imgAlt: "Dollar Icon",
                content: "Acquired",
            },
        },
        {
            link: "/drawbridge",
            image: "img/drawbridge.svg",
            alt: "Drawbridge Marketing",
            title: "Drawbridge Marketing",
            description: "eCommerce Marketing Agency",
            tags: [
                "Product Photography/Renders",
                "Infographics",
                "Video",
                "Copywriting",
                "Enhanced Brand Content",
                "Storefront Design & Development",
                "Ad Creative",
            ],
        },
        {
            link: "/rise",
            image: "img/rise.svg",
            alt: "Rise Foundation",
            title: "Rise Foundation",
            description: "Intranet in a box",
            tags: ["UX Research", "UX Design ", "Wireframing", "Visual Design"],
        },
    ];

    useEffect(() => {
        document.title = "Dan Ottenad - Full Stack Developer";
        TagManager.dataLayer({
            dataLayer: {
                page: "Drawbridge",
            },
        });

        // Load all images (if any) before displaying content
        const imagePromises = projects.map((project) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = bannerImage;
                img.src = project.image;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(imagePromises)
            .then(() => setIsLoading(false))
            .catch((error) => console.error("Error loading images:", error));
    }, []); // No dependencies, runs only once

    if (isLoading) {
        return <Spinner />;
    }
    return (
        <>
            <div className="mx-auto max-w-7xl sm:px-6 pt-6 pb-12 lg:px-8">
                <div className="relative isolate overflow-hidden px-6 text-center sm:px-16">
                    <img
                        className="mx-auto h-64 w-64 rounded-full mb-6"
                        src="img/pic.jpg"
                        alt=""
                    />
                    <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
                        Dan Ottenad
                    </h2>
                    <p className="mx-auto mt-2 max-w-xl text-lg leading-8 text-gray-900 dark:text-gray-100">
                        Full-Stack Developer
                    </p>
                    <p className="mx-auto max-w-xl text-sm leading-8 text-gray-900 dark:text-gray-100">
                        Seattle, WA
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 z-10">
                {projects.map((project, index) => (
                    <div key={index} className="mx-4 mb-4">
                        <Link to={project.link} className="cursor-pointer">
                            <div className="cursor-pointer overflow-hidden rounded-lg bg-gray-200 dark:bg-gray-700 shadow border border-gray-200 dark:border-gray-600">
                                <img
                                    src={project.image}
                                    className="w-full cursor-pointer"
                                    alt={project.alt}
                                />
                            </div>
                        </Link>
                        <div className="inline-flex items-baseline">
                            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100 mt-3">
                                {project.title}
                            </h3>
                            {project.popover && (
                                <div className="has-popover">
                                    <img
                                        src={project.popover.imgSrc}
                                        className="ml-2 relative top-1"
                                        style={{ height: "20px" }}
                                        alt={project.popover.imgAlt}
                                    />
                                    <Popover>{project.popover.content}</Popover>
                                </div>
                            )}
                        </div>
                        <p className="mt-1 mb-3 text-sm text-gray-500 dark:text-gray-400">
                            {project.description}
                        </p>
                        <div className="flex flex-wrap">
                            {project.tags.map((tag, index) => (
                                <div
                                    key={index}
                                    className="relative z-10 rounded-full bg-gray-200 dark:bg-gray-700 px-3 py-1.5 font-medium text-xs text-gray-900 dark:text-gray-100 hover:bg-gray-100 mr-1 mb-2"
                                >
                                    {tag}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
