import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    EnvelopeIcon,
    MoonIcon,
    SunIcon,
    DocumentIcon,
} from "@heroicons/react/24/outline";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function Navigation() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem("darkMode");
        return savedMode !== null ? savedMode === "true" : true;
    });

    useEffect(() => {
        const scrollHandler = () => {
            const offset = window.scrollY;
            if (offset > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add("dark");
            // save selection in local storage
            localStorage.setItem("darkMode", "true");
        } else {
            document.documentElement.classList.remove("dark");
            // save selection in local storage
            localStorage.setItem("darkMode", "true");
        }
    }, [darkMode]);

    const icons = [
        {
            icon: (
                <EnvelopeIcon className="h-11 w-11 rounded-full p-2 dark:text-gray-100 bg-gray-200 dark:text-gray-100 dark:bg-gray-900" />
            ),
            link: "mailto:ottenadd@gmail.com?subject=Hi&body=%0D%0A%0D%0A--Sent%20from%20danottenad.com",
            popoverContent: "Email Me",
        },
        {
            icon: (
                <FontAwesomeIcon
                    icon={faGithub}
                    className="h-7 w-7 rounded-full p-2 dark:text-gray-100 bg-gray-200 dark:text-gray-100 dark:bg-gray-900"
                />
            ),
            link: "https://github.com/dottenad",
            popoverContent: "GitHub",
        },
        {
            icon: (
                <DocumentIcon className="h-11 w-11 rounded-full p-2 dark:text-gray-100 bg-gray-200 dark:text-gray-100 dark:bg-gray-900" />
            ),
            link: "/Dan_Ottenad_Resume.pdf",
            popoverContent: "Resume",
        },
        {
            icon: (
                <button onClick={() => setDarkMode(!darkMode)}>
                    {darkMode ? (
                        <SunIcon className="h-11 w-11 relative -top-0.5 rounded-full p-2 dark:text-gray-100 bg-gray-200 dark:text-gray-100 dark:bg-gray-900" />
                    ) : (
                        <MoonIcon className="h-11 w-11 relative -top-0.5 rounded-full p-2 dark:text-gray-100 bg-gray-200 dark:text-gray-100 dark:bg-gray-900" />
                    )}
                </button>
            ),
            popoverContent: darkMode ? "Light Mode" : "Dark Mode",
            isButton: true,
        },
    ];
    return (
        <>
            <style>
                {`
        .has-popover {
            position: relative;
            display: inline-flex;
            align-items: center;
        }
        
        .popover {
            display: none;
            position: absolute;
            top: calc(100% + 10px);
            left: 22px;
            transform: translateX(-50%);
            z-index: 20;
            width: 80px;
            height: 32px;
            border-radius: 3px;
            background: #374151;
            color: white;
            padding: 7px;
            text-align: center;
            font-size: 12px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        }
        
        .popover .carat {
            position: absolute;
            top: -7px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 15px;
            height: 15px;
            background: #374151;
        }

        .button-container .popover {
            top: calc(100% + 4px);
        }
        
        .button-container .popover .carat {
            top: -6px;
        }
        
        .has-popover:hover .popover {
            display: inline-block;
        }
        
        `}
            </style>
            <div
                className={`max-w-full sticky top-0 z-50 bg-white dark:bg-gray-800 ${
                    isScrolled ? "shadow" : ""
                }`}
            >
                <div className="mx-auto max-w-5xl px-8">
                    <div className="py-6 flex justify-between items-center">
                        <div>
                            <Link to="/">
                                <img
                                    src="img/logo.svg"
                                    alt="Logo"
                                    className="h-10 self-center"
                                />
                            </Link>
                        </div>
                        <div className="flex space-x-4">
                            {icons.map((icon, index) => {
                                if (icon.isButton) {
                                    return (
                                        <div
                                            className="has-popover button-container"
                                            key={index}
                                        >
                                            {icon.icon}
                                            <div className="popover">
                                                <div className="carat"></div>
                                                {icon.popoverContent}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <a
                                            href={icon.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            key={index}
                                        >
                                            <div className="has-popover">
                                                {icon.icon}
                                                <div className="popover">
                                                    <div className="carat"></div>
                                                    {icon.popoverContent}
                                                </div>
                                            </div>
                                        </a>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
