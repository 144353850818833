import { useState } from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

export default function LightboxComponent({ images }) {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    return (
        <>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
                {images.map((img, index) => (
                    <div key={img.url} className="flex-1 ml-0 mx-4 mb-4">
                        <div className="cursor-pointer overflow-hidden rounded-lg bg-gray-200 dark:bg-gray-700 shadow border border-gray-200 dark:border-gray-600 image-container">
                            <img
                                src={img.url}
                                className="w-full cursor-pointer"
                                alt="Gotomarket.tools"
                                onClick={() => {
                                    setLightboxOpen(true);
                                    setCurrentImage(index); // Set the current image index to the index of the clicked image
                                }}
                            />
                        </div>
                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                            {img.title}
                        </p>
                    </div>
                ))}
            </div>
            {lightboxOpen && (
                <Lightbox
                    images={images} // Pass the images array to the Lightbox
                    startIndex={currentImage} // Pass the current image index to the Lightbox
                    onClose={() => setLightboxOpen(false)}
                />
            )}
        </>
    );
}
