import { useState, useEffect, useMemo } from "react";
import ProjectDetails from "../Components/ProjectDetails";
import PrimaryImage from "../Components/PrimaryImage";
import Spinner from "../Components/Spinner";
import TagManager from "react-gtm-module";

export default function Rise() {
    useEffect(() => {
        document.title = "Getreviews.ai - Dan Ottenad - Full Stack Developer";
    }, []);
    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "GetReviews",
            },
        });
    }, []);
    const images = useMemo(
        () => [
            {
                url: "img/rise_wf_1.png",
                title: "Wireframe Home Page",
            },
            {
                url: "img/rise_wf_2.png",
                title: "Wireframe Article Page",
            },
            {
                url: "img/rise_vd_1.png",
                title: "Wireframe Home Page",
            },
            {
                url: "img/rise_vd_2.png",
                title: "Wireframe Article Page",
            },
            {
                url: "img/rise_vd_3.png",
                title: "Wireframe Home Page",
            },
            {
                url: "img/rise_vd_4.png",
                title: "Wireframe Article Page",
            },
            {
                url: "img/rise_vd_5.png",
                title: "Wireframe Home Page",
            },
            {
                url: "img/rise_vd_6.png",
                title: "Wireframe Article Page",
            },
        ],
        []
    );

    const projectDetails = {
        title: <h1 className="text-xl">Rise Foundation</h1>, // Main title as an h1
        description: [
            <div>
                <p className="mb-2">
                    Rise Foundation is a product for SharePoint that gives IT
                    teams the ability to easily launch and brand their corporate
                    intranet. After spending years in the enterprise software
                    consulting business, there became a common theme in most of
                    the projects that we were doing. For the projects that
                    required a corporate communications portal, most companies
                    asked for the same functionality, which included stock
                    tickers, featured image rotators, favoriting functionality,
                    recent news, and more. So what Rise set out to accomplish
                    was to put all of these features into an easily brandable
                    package that we would sell to small to medium size
                    businesses that required this type of functionality.
                </p>
                <h2 className="mb-2 text-lg font-bold text-white">Process</h2>
                <p className="mb-2">
                    Rise Foundation was built on the back of previous research,
                    IA, wireframes, and visual designs that the team at Blue
                    Rooster and I conducted for previous clients. User research
                    played an important role in the construction of Rise
                    Foundation in that we wanted to know what employees wanted
                    to see when they visited their corporate communication
                    portal. We conducted user interviews with a wide range of
                    employees, from factory workers who checked the corporate
                    intranet periodically and on mobile devices, to desk workers
                    who checked the corporate intranet many times a day on a
                    desktop. There were commonalities in the features that both
                    parties wanted, so we took these into account and began
                    wireframing.
                </p>
                <h2 className="mb-2 text-lg font-bold text-white">Roles</h2>
                <p className="mb-2">
                    <span class="text-white font-bold">
                        Research Assistant:
                    </span>{" "}
                    Traveled to multiple different companies with a user
                    experience researcher to conduct group task analysis,
                    heuristic reviews, and user interviews. This provided us
                    with the feature foundation for Rise.
                </p>
                <p className="mb-2">
                    <span class="text-white font-bold">Wireframes:</span>{" "}
                    Created wireframes that displayed visually what we heard
                    from our clients. This allowed us to open the dialogue as to
                    whether or not we hit the mark with our research. My
                    wireframes are inspired by sketches, whiteboard sessions,
                    and communication with other designers, researchers, project
                    managers, the client, and leadership.
                </p>
                <p className="mb-2">
                    <span class="text-white font-bold">Visual Design:</span>{" "}
                    Once the wireframes were finalized, it was time to move into
                    visual design. For Rise, it was crucial that the visual
                    design was able to accomodate any number of primary colors,
                    as this design needed to be outfitted with other brands
                    colors. With this in mind, I only applied primary colors to
                    specific areas. In my Illustrator file, I also left areas
                    where accent colors could be applied, in case a company had
                    two brand colors that carried equal weight.
                </p>
                <p className="mb-2">
                    <span class="text-white font-bold">Marketing:</span> A
                    product is only as good as the sales that you make with it.
                    So with this in mind, I used my video creation talents and
                    my deep understanding of the product to screenwrite, direct,
                    film, and edit a product promotional video, as well as a
                    product deep dive video.
                </p>
            </div>,
        ],
        gallery: "Gallery",
        skills: {
            title: "Skills",
            skillsList: [
                ["UX Research", "UX Design", "Wireframing", "Visual Design"],
            ],
        },
        team: {
            title: "Team",
            description: "Blue Rooster",
        },
        link: {
            title: "",
            url: "",
        },
    };

    const primaryImage = useMemo(
        () => ({
            url: "img/rise.png",
        }),
        []
    );

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const allImages = [primaryImage, ...images];

        const imagePromises = allImages.map((image) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = image.url;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(imagePromises)
            .then(() => setIsLoading(false))
            .catch((error) => console.error("Error loading images:", error));
    }, [primaryImage, images]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="px-4">
                <PrimaryImage primaryImage={primaryImage} />
                <ProjectDetails
                    images={images}
                    projectDetails={projectDetails}
                />
            </div>
        </>
    );
}
