import { useState, useEffect, useMemo } from "react";
import ProjectDetails from "../Components/ProjectDetails";
import PrimaryImage from "../Components/PrimaryImage";
import Spinner from "../Components/Spinner";
import TagManager from "react-gtm-module";

export default function Getreviews() {
    useEffect(() => {
        document.title = "Getreviews.ai - Dan Ottenad - Full Stack Developer";
    }, []);
    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "GetReviews",
            },
        });
    }, []);
    const images = useMemo(
        () => [
            {
                url: "img/gr_1.jpg",
                title: "Marketing Site",
            },
            {
                url: "img/gr_2.jpg",
                title: "Survey",
            },
        ],
        []
    );

    const projectDetails = {
        title: "getreviews.ai",
        description: [
            "GetReviews.ai is a review funnel optimized to generate reviews on platforms that matter to your business. This is done by taking your customer through a survey containing a set of highly-optimized questions that result in reviews on the platform(s) of your choosing.",
            "GetReviews spawned from my consulting of Amazon brands where this type of review funnel became a commonly requested service. I began GetReviews with static, one-off PHP/MySQL sites that connected to Amazon MWS, but as more and more customers began requesting the same thing, I migrated all clients over to a MERN-stack app to allow for easier scaling. GetReviews started in June of 2020, and my co-founder and I sold GetReviews in September of 2022.",
        ],
        gallery: "Gallery",
        skills: {
            title: "Skills",
            skillsList: [
                ["Frontend Dev", "Strategy", "Sales", "Marketing"],
                ["Support"],
            ],
        },
        team: {
            title: "Team",
            description: "Myself + Technical Cofounder",
        },
        link: {
            title: "Link",
            url: "https://www.getreviews.ai",
        },
    };

    const primaryImage = useMemo(
        () => ({
            url: "img/getreviews_comps.png",
        }),
        []
    );

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const allImages = [primaryImage, ...images];

        const imagePromises = allImages.map((image) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = image.url;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(imagePromises)
            .then(() => setIsLoading(false))
            .catch((error) => console.error("Error loading images:", error));
    }, [primaryImage, images]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="px-4">
                <PrimaryImage primaryImage={primaryImage} />
                <ProjectDetails
                    images={images}
                    projectDetails={projectDetails}
                />
            </div>
        </>
    );
}
