import { useState, useEffect, useMemo } from "react";
import ProjectDetails2 from "../Components/ProjectDetails2";
import PrimaryImage from "../Components/PrimaryImage";
import Spinner from "../Components/Spinner";
import TagManager from "react-gtm-module";

export default function Drawbridge() {
    useEffect(() => {
        document.title =
            "Drawbridge Marketing - Dan Ottenad - Full Stack Developer";
    }, []);
    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "Drawbridge",
            },
        });
    }, []);
    const images = useMemo(() => [
        {
            url: "img/db_1.jpg",
            title: "Enhanced Brand Content Design",
        },
        {
            url: "img/db_6.jpg",
            title: "Storefront Design",
        },

        {
            url: "img/db_13.jpg",
            title: "Product Render",
        },
        {
            url: "img/db_10.jpg",
            title: "Product Render",
        },
        {
            url: "img/db_7.jpg",
            title: "Product Render",
        },
    ]);

    const projectDetails = {
        title: "Drawbridge Marketing",
        description: [
            "Drawbridge Marketing is a creative marketing agency specializing in creating assets that help eCommerce brands sell product more effectively. Drawbridge Marketing started during my time at Custom Plus Distributing, where the brands we represented also had Amazon presences that needed a variety of different creative assets.",
            "Utilizing my background in graphic design, I opened up shop creating assets for eCommerce sellers.",
        ],
        gallery: "Gallery",
        skills: {
            title: "Skills",
            skillsList: [
                [
                    "Product Photography/Renders",
                    "Infographics",
                    "Video",
                    "Copywriting",
                    "Enhanced Brand Content",
                    "Storefront Design & Development",
                    "Ad Creative",
                ],
            ],
        },
        team: {
            title: "Team",
            description: "Solo project",
        },
        link: {
            title: "Link",
            url: "https://www.drawbridgemarketing.com",
        },
    };

    const primaryImage = useMemo(
        () => ({
            url: "img/Drawbridge_1.png",
        }),
        []
    );

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const allImages = [primaryImage, ...images];

        const imagePromises = allImages.map((image) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = image.url;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(imagePromises)
            .then(() => setIsLoading(false))
            .catch((error) => console.error("Error loading images:", error));
    }, [primaryImage, images]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="px-4">
                <PrimaryImage primaryImage={primaryImage} />
                <ProjectDetails2
                    images={images}
                    projectDetails={projectDetails}
                />
            </div>
        </>
    );
}
